import React, { useEffect } from "react";

import { currentUserVar, useReactiveVar } from "@apollo";

// Misc Imports
import { Theme } from "@app-types";
import { useTranslation } from "@i18n";
import { makeStyles } from "@styles";
import { navigate } from "@utils";

// Component imports
import { ResetPasswordForm } from "@components";
import { Grid, Paper, Typography } from "@material";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    height: "auto",
    width: 372,
    border: "1px solid #DADADA",
    borderRadius: 8,
    margin: "auto",
    marginTop: 60,
    marginBottom: 127,
    backgroundColor: "white",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      width: "95%",
    },
  },
  container: {
    width: 308,
    margin: "3px auto 13px auto",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      margin: "16px auto 16px auto",
    },
  },
  header: {
    marginTop: 20,
    fontSize: 24,
    fontWeight: 500,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      marginTop: 16,
      fontSize: 20,
    },
  },
  subHeader: {
    marginTop: 16,
    fontSize: 18,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      marginTop: 16,
      fontSize: 16,
    },
  },
}));

export default function ResetPassword({ location }) {
  const classes: any = useStyles({});
  const { t } = useTranslation(["resetPassword"]);

  const user = useReactiveVar(currentUserVar);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location?.state?.referrer !== "reset-password-modal" || !!user) {
      navigate("/", { replace: true });
    }
  }, []);

  const emailAddress: string = location?.state?.emailAddress;

  return location?.state?.referrer !== "reset-password-modal" ||
    !!user ? null : (
    <Paper className={classes.paper}>
      <Grid
        container
        item
        direction="column"
        justify="center"
        alignItems="center"
        xs={12}
        sm={10}
        className={classes.container}
      >
        <Grid>
          <Typography className={classes.header}>
            {t("resetPassword")}
          </Typography>
        </Grid>
        <Grid>
          <Typography className={classes.subHeader}>
            {t("enterTheCode")}
          </Typography>
        </Grid>
        <Grid>
          <ResetPasswordForm emailAddress={emailAddress} />
        </Grid>
      </Grid>
    </Paper>
  );
}
